import { Progress } from "antd";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { NFTProps } from "../../views/CollectionPage/NFTBox";
import { COLLECTION_STATES } from "../constants";
import { Wrapper } from "./styles";

interface LoadingBarProps {
  state: COLLECTION_STATES;
  nfts: Partial<NFTProps>[];
  id: number;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ state, nfts, id }) => {
  const { deployMintStatus } = useContext(GlobalContext);

  const done = deployMintStatus[id]?.mintComplete;

  if (state !== COLLECTION_STATES.PUBLISHING || done) {
    return null;
  }

  const mintedNumber = Object.keys(deployMintStatus[id]?.nfts || []).length;

  return (
    <Wrapper>
      <div>Publishing collection...</div>
      <Progress percent={Math.round((100 * mintedNumber) / nfts.length)} status={done ? "success" : "active"} />
    </Wrapper>
  );
};

export default LoadingBar;
