import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Upload } from "antd";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { createCollection, editCollection } from "../../api";
import { LocationState, ParamsProps } from "../CollectionPage";
import Header from "../../shared/Header";
import { FormWrapper, ImagePreview, Wrapper } from "./styles";
import { getImageFromBase64 } from "../../shared/helpers";

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export interface CollectionFormProps {
  name: string;
  symbol: string;
  claim_code?: string;
  description?: string;
  logo: File;
}

interface CreateOrEditCollectionPage {
  edit?: boolean;
}

const CreateColection: React.FC<CreateOrEditCollectionPage> = ({ edit }) => {
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [mediaUrl, setMediaUrl] = useState(edit ? location.state?.collection?.logo : undefined);
  const params = useParams<ParamsProps>();
  const initialValues = location.state?.collection;

  const actions = [
    <Button type="primary" shape="round" size="large" onClick={() => history.push("/collections")}>
      View Collections
    </Button>,
  ];

  const onFinish = async (values: any) => {
    const formData: any = new FormData();

    for (const name in values) {
      if (values[name]) {
        formData.append(name, values[name]);
      }
    }

    setFormLoading(true);
    const id = edit ? await editCollection(params.id, formData) : await createCollection(formData);

    if (id) {
      message.success(`Collection ${edit ? "edited" : "created"}!`);
      history.push(`/collections`);
    }

    setFormLoading(false);
  };

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getImageFromBase64(info.file.originFileObj, (mediaUrl: string) => {
        setLoading(false);
        setMediaUrl(mediaUrl);
      });
    }
  };

  function beforeUpload(file: File) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Wrapper>
      <Header title={`${edit ? "Edit" : "Create"} your collection`} actions={actions} withBackArrow />

      <FormWrapper
        name="create_collection"
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={
          initialValues
            ? {
                description: initialValues.description,
                name: initialValues.name,
                logo: initialValues.logo,
                symbol: initialValues.symbol,
                claim_code: initialValues.claim_code,
              }
            : {}
        }
      >
        <Form.Item
          name="logo"
          label="Logo"
          valuePropName="file"
          extra="(350 x 350 recommended)"
          getValueFromEvent={(e) => e && e.file.originFileObj}
          rules={[{ required: !edit, message: "Please upload logo" }]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {mediaUrl ? <ImagePreview src={mediaUrl} alt="logo" /> : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input name" }]}>
          <Input placeholder="CryptoPunks" />
        </Form.Item>

        <Form.Item
          label="Symbol"
          name="symbol"
          rules={[{ required: true, message: "Please input symbol" }]}
          tooltip="1-4 letter combination (C, CP, BTC, ETH...)"
        >
          <Input placeholder="CP" />
        </Form.Item>

        {edit && (
          <Form.Item
            label="Claim Code"
            name="claim_code"
            rules={[{ required: true, message: "Please input claim code, must be 5 digits" }]}
            tooltip="This code will be used to claim the drop, must be 5 digits long"
          >
            <Input placeholder="00000" maxLength={5} minLength={5} />
          </Form.Item>
        )}

        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="The CryptoPunks are 10,000 uniquely generated characters. No two are exactly alike..." showCount maxLength={255} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round" loading={formLoading}>
            Submit
          </Button>
        </Form.Item>
      </FormWrapper>
    </Wrapper>
  );
};

export default CreateColection;
