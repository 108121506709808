import { Col } from "antd";
import styled from "styled-components";

export const PublishMessageWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  a.ant-btn-link {
    padding: 0 5px;
  }
`;

export const ScheduleContentWrapper = styled.div`
  margin: 2rem 0;

  .ant-row.ant-form-item {
      margin-bottom: 0;
    }

  .ant-radio-button-wrapper {
    width: 100px;
    height: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    &:first-child {
      margin-right: 2rem;
    }
  }
`;

export const ScheduleTypeText = styled(Col)`
  text-align: center;
  margin-bottom: 2rem;
`;