import { message } from "antd";
import axios from "axios";
import history from "./history";

const defaultOptions = {
  baseURL: "/api",
  method: "get",
  headers: {
    "Content-Type": "application/json",
  },
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  (error) => {
    if (error.response.status === 403) {
      history.push("/login", { logout: true });
    }

    return Promise.reject(error);
  }
);

export default instance;
