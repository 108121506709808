import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
  && {
    .ant-select-selector {
      min-width: 110px;
      border-radius: 5px;
    }
  }

  & > * + * {
    margin-left: 1rem;
  }
`;