import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const AuthGate: React.FC<any> = ({ children }) => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      auth.setUser(true);
    }
  }, [auth]);

  useEffect(() => {
    if (auth.user && location.pathname === '/login') {
      history.push('/collections');
    }
  }, [auth, location]);

  return children;
};

export default AuthGate;
