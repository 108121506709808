import {
  Button,
  message,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Divider,
  Radio,
  FormInstance,
} from "antd";
import moment from "moment";
import {
  deleteCollection,
  dropCollection,
  editCollection,
  publishCollection,
} from "../api";
import { CollectionProps } from "../views/CollectionPage";
import {
  DATE_FORMAT,
  DROP_TYPES,
  MYSQL_FORMAT,
  TIME_FORMAT,
} from "./constants";
import {
  PublishMessageWrapper,
  ScheduleContentWrapper,
  ScheduleTypeText,
} from "./styles";

interface DatePickerProps extends FormInstance {
  date: any /* Moment instance */;
  time: any /* Moment instance */;
  type: string;
}

export const onDelete = (id: number, history: any, callback?: () => void) => {
  Modal.confirm({
    title: "Are you sure you want to delete?",
    content:
      "If you delete this collection, you will delete ALL NFT's that were part of it!",
    okText: "Delete",
    cancelText: "No",
    okType: "danger",
    onOk: async () => {
      await deleteCollection(id);
      history.push("/collections");
      message.success("Collection deleted!");

      callback?.();
    },
  });
};

export const onPublish = (id: number, callback: () => void): void => {
  Modal.confirm({
    title: "Are you sure you want to publish?",
    content:
      "You will publish every NFT in this collection. This action is irreversible!",
    okText: "Publish",
    cancelText: "No",
    okType: "primary",
    onOk: async () => {
      await publishCollection(id);
      await message.loading("Publishing...", 2);

      message.success(
        <PublishMessageWrapper>
          <span>Publishing started...</span>
        </PublishMessageWrapper>,
        8
      );

      callback?.();
    },
  });
};

export const onDrop = (id: number, callback: () => void): void => {
  Modal.confirm({
    title: "Are you sure you want to drop the collection now?",
    content:
      "The collection will be dropped immediately. This action is irreversible!",
    okText: "Drop",
    cancelText: "No",
    okType: "primary",
    onOk: async () => {
      await dropCollection(id);
      await message.loading("Dropping...", 2);

      message.success(
        <PublishMessageWrapper>
          <span>Collection has been droped </span>
        </PublishMessageWrapper>
      );

      callback?.();
    },
  });
};

export const onSchedule = (
  collection: CollectionProps,
  form: FormInstance,
  callback: () => void
): void => {
  form.setFieldsValue({
    type: collection.drop_type,
    ...(collection.drop_scheduled_at && {
      date: moment.utc(collection.drop_scheduled_at),
      time: moment.utc(collection.drop_scheduled_at),
    }),
  });

  Modal.confirm({
    title: "Schedule a drop",
    okText: "Schedule",
    cancelText: "Cancel",
    okType: "primary",
    maskClosable: true,
    content: (
      <ScheduleContentWrapper>
        <Form form={form} layout="vertical" preserve={false}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Date is required" }]}
                requiredMark={false}
              >
                <DatePicker format={DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="time"
                rules={[{ required: true, message: "Time is required" }]}
                requiredMark={false}
              >
                <TimePicker minuteStep={15} format={TIME_FORMAT} use12Hours />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <ScheduleTypeText>
              Would you like to drop manually or automatically?
            </ScheduleTypeText>
          </Row>
          <Row justify="center">
            <Form.Item
              name="type"
              rules={[{ required: true, message: "Type is required" }]}
              requiredMark={false}
            >
              <Radio.Group optionType="button" buttonStyle="outline">
                {[DROP_TYPES.AUTO, DROP_TYPES.MANUAL].map((value) => (
                  <Radio.Button key={value} value={value}>
                    {value}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Row>
        </Form>
      </ScheduleContentWrapper>
    ),
    onOk: async () => {
      await form.validateFields();

      const { date, time, type } = form.getFieldsValue() as DatePickerProps;
      const dateFormatted = date.format(DATE_FORMAT);
      const timeFormatted = time.format(TIME_FORMAT);
      const datetimeString = `${dateFormatted} ${timeFormatted}`;

      const drop_scheduled_at = moment(
        datetimeString,
        `${DATE_FORMAT}, ${TIME_FORMAT}`
      ).format(MYSQL_FORMAT);
      const drop_type = type;

      await editCollection(collection.id, {
        drop_scheduled_at,
        drop_type,
      } as CollectionProps);

      message.success(
        `Drop schedulled for ${dateFormatted} at ${timeFormatted}`,
        10
      );
      message.success(`Collection with id: ${collection.id} will drop ${type}`);

      callback?.();
    },
    afterClose: () => {
      form.resetFields();
    },
  });
};
