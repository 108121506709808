import moment from "moment";

const applySort = (element1: any, element2: any, sort: string) => {
  const isAfter = moment(element1.created_at).isAfter(element2.created_at);
  const isDropAfter = moment(element1.drop_scheduled_at).isAfter(element2.drop_scheduled_at);

  if (sort === 'name') {
    return element1.name.localeCompare(element2.name);
  }
  if (sort === 'newest') {
    if (isAfter) return -1;
    return 1;
  }
  if (sort === 'oldest') {
    if (isAfter) return 1;
    return -1;
  }
  if (sort === 'dropDate') {
    if (!element1.drop_scheduled_at) return -1;
    if (!element2.drop_scheduled_at) return 1;
    if (isDropAfter) return 1;
    return -1;
  }

  return true;
};

export default applySort;
