import { Tooltip as AntdTooltip } from "antd";

export interface TooltipProps {
  text: string;
  visible?: boolean;
  children: React.ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({ text, visible = true, children, ...rest }) => {
  if (!visible) {
    return children;
  }

  return (
    <AntdTooltip title={text} overlayInnerStyle={{ textAlign: "center" }} {...rest}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
