import React, { createContext, useReducer } from "react";
import { checkIfEverythingIsMinted as apiCheckIfEverythingIsMinted, getCollection as apiGetCollection, getCollections as apiGetCollections } from "../api";
import { CollectionProps } from "../views/CollectionPage";
import AppReducer, { CLOSE_MODAL, GET_COLLECTION, GET_COLLECTIONS, OPEN_MODAL, UPDATE_COLLECTION_STATE, UPDATE_NFT_STATE } from "./AppReducer";

interface CollectionDeployedMintedStates {
  [collectionId: number]: {
    deployed: boolean;
    mintComplete: boolean;
    address?: string;
    nfts: {
      [id: number]: {
        minted: boolean;
      };
    };
  };
}

interface GlobalContextProps {
  collections: CollectionProps[];
  deployMintStatus: CollectionDeployedMintedStates;
  singleCollection: Partial<CollectionProps> | undefined;
  getCollections: () => void;
  getCollection: (id: number | string) => void;
  updateDeployedCollection: ({ collectionId, event, address }: any) => void;
  updateMintedNFT: ({ collectionId, nftId, event }: any) => void;
  modal: {
    name: string;
    data: any;
  };
  openModal: (name: string, data: any) => void;
  closeModal: () => void;
}

const initialState = {
  collections: [],
  deployMintStatus: {},
  singleCollection: {},
  getCollections: () => {},
  getCollection: () => {},
  updateDeployedCollection: () => {},
  updateMintedNFT: () => {},
  modal: {
    name: "",
    data: {},
  },
  openModal: (name: string, data: any) => {},
  closeModal: () => {},
};

export const GlobalContext = createContext<GlobalContextProps>(initialState);

export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  async function getCollections() {
    const payload = await apiGetCollections();

    dispatch({
      type: GET_COLLECTIONS,
      payload,
    });
  }
  async function getCollection(id: number | string) {
    const payload = await apiGetCollection(id);

    dispatch({
      type: GET_COLLECTION,
      payload,
    });
  }

  function updateDeployedCollection({ collectionId, address, event }: { collectionId: number; address: string; event: string }) {
    dispatch({
      type: UPDATE_COLLECTION_STATE,
      payload: { collectionId, address, event },
    });
  }

  function updateMintedNFT({ collectionId, nftId, event }: { collectionId: number; nftId: string; event: string }) {
    dispatch({
      type: UPDATE_NFT_STATE,
      payload: { collectionId, nftId, event },
    });
  }

  function openModal(name: string, data: any) {
    dispatch({
      type: OPEN_MODAL,
      payload: { name, data },
    });
  }

  function closeModal() {
    dispatch({
      type: CLOSE_MODAL,
    });
  }

  const providerValue = {
    ...state,
    getCollection,
    getCollections,
    updateDeployedCollection,
    updateMintedNFT,
    openModal,
    closeModal,
  };

  return <GlobalContext.Provider value={providerValue}>{children}</GlobalContext.Provider>;
};
