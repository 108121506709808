import { Divider } from "antd";
import styled from "styled-components";

export const CodeCheckBox = styled.input`
  margin-right: 1.5px;
`;

export const Separator = styled(Divider)`
  margin-left: 2rem;
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
`;

export const LoadingContainer = styled.div`
  padding: 0 2rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem;

  & > * + * {
    margin-left: 1rem;
  }

  label:last-of-type {
    margin-left: 2px;
  }
`;
