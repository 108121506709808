import { filter } from "lodash";
import { COLLECTION_STATES } from "../constants";

const applyFilters = (element: any, filters: string[] | string) => {
  if (typeof filters === "string") {
    return element.status === filters;
  }

  if (!filters.length) return true;

  if (filters.includes("published") && filters.includes("scheduled")) {
    return (
      element.state === COLLECTION_STATES.PUBLISHED && element.drop_scheduled_at
    );
  }

  if (filters.includes("published")) {
    return element.state === COLLECTION_STATES.PUBLISHED;
  }
  if (filters.includes("scheduled")) {
    return Boolean(element.drop_scheduled_at);
  }
  if (filters.includes("dropped")) {
    return element.state === COLLECTION_STATES.DROPPED;
  }

  return true;
};

export default applyFilters;
