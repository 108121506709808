import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, List, Form } from "antd";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { onDelete, onDrop, onPublish, onSchedule } from "../../shared/actions";
import {
  COLLECTION_STATES,
  DATE_FORMAT,
  TIME_FORMAT,
  COLORS,
} from "../../shared/constants";
import { CollectionProps } from "../CollectionPage";
import { CollectionImage, ListTitle, ListWrapper, PublishTag } from "./styles";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { PolygonscanButton, ActionButton } from "../CollectionPage/styles";
import { BsArrowUpRight } from "react-icons/bs";
import LoadingBar from "../../shared/LoadingBar";
import Tooltip from "../../shared/Tooltip";

interface CollectionListProps {
  collections: CollectionProps[];
}

const CollectionList: React.FC<CollectionListProps> = ({ collections }) => {
  const { getCollections, deployMintStatus } = useContext(GlobalContext);
  const history = useHistory();
  const [form] = Form.useForm();

  return (
    <ListWrapper>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={collections}
        renderItem={(item: CollectionProps) => (
          <List.Item
            key={item.id}
            actions={[
              ...([
                COLLECTION_STATES.PUBLISHED,
                COLLECTION_STATES.PUBLISHING,
                COLLECTION_STATES.DROPPED,
              ].includes(item.state)
                ? [
                    <PolygonscanButton
                      shape="round"
                      href={`https://mumbai.polygonscan.com/address/${
                        deployMintStatus[item.id]?.address || item.address
                      }`}
                      target="_blank"
                      key={item.id}
                    >
                      Polygonscan
                      <BsArrowUpRight />
                    </PolygonscanButton>,

                    deployMintStatus[item.id]?.mintComplete && [
                      COLLECTION_STATES.DROPPED !== item.state && [
                        <Button
                          type="primary"
                          shape="round"
                          onClick={() => onSchedule(item, form, getCollections)}
                          key={item.id}
                        >
                          {!item.drop_scheduled_at
                            ? "Schedule Drop"
                            : "Edit Scheduled Date"}
                        </Button>,

                        item.drop_type !== "AUTO" && (
                          <ActionButton
                            type="default"
                            shape="round"
                            onClick={() => onDrop(item.id, getCollections)}
                            key={item.id}
                          >
                            Drop
                          </ActionButton>
                        ),
                      ],
                    ],
                  ]
                : [
                    <Button
                      type="default"
                      shape="round"
                      onClick={() =>
                        history.push({
                          pathname: `/collections/${item.id}/edit`,
                          state: { collection: item },
                        })
                      }
                      key={item.id}
                    >
                      Edit
                    </Button>,
                    <Button
                      shape="round"
                      danger
                      onClick={() => onDelete(item.id, history, getCollections)}
                      key={item.id}
                    >
                      Delete
                    </Button>,
                    <Button
                      type="default"
                      shape="round"
                      onClick={() => onPublish(item.id, getCollections)}
                      key={item.id}
                    >
                      Publish
                    </Button>,
                  ]),
            ]}
            extra={<CollectionImage alt="logo" src={item.logo} />}
          >
            <List.Item.Meta
              title={
                <Link
                  to={{
                    pathname: `/collections/${item.id}`,
                    state: { collection: item },
                  }}
                >
                  <div>
                    <ListTitle>{item.name}</ListTitle>
                    {deployMintStatus[item.id]?.mintComplete
                      ? [
                          COLLECTION_STATES.DROPPED !== item.state && (
                            <PublishTag
                              color={COLORS.green}
                              icon={<CheckCircleOutlined />}
                            >
                              published
                            </PublishTag>
                          ),
                          COLLECTION_STATES.DROPPED === item.state && (
                            <PublishTag
                              color={COLORS.green}
                              icon={<CheckCircleOutlined />}
                            >
                              dropped
                            </PublishTag>
                          ),
                        ]
                      : COLLECTION_STATES.PUBLISHING === item.state && (
                          <PublishTag
                            color={COLORS.orange}
                            icon={<CheckCircleOutlined />}
                          >
                            publishing
                          </PublishTag>
                        )}
                    {COLLECTION_STATES.PUBLISHED === item.state &&
                      item.drop_scheduled_at && (
                        <Tooltip
                          text={`scheduled for ${moment(
                            item.drop_scheduled_at
                          ).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)}`}
                        >
                          <PublishTag
                            visible={Boolean(item.drop_scheduled_at)}
                            color={COLORS.orange}
                            icon={<ClockCircleOutlined />}
                          >
                            scheduled
                          </PublishTag>
                        </Tooltip>
                      )}
                    {item.live && (
                      <PublishTag
                        color={COLORS.red}
                        icon={<CheckCircleOutlined />}
                      >
                        live
                      </PublishTag>
                    )}
                  </div>
                </Link>
              }
              description={item.description}
            />

            <LoadingBar {...item} />
          </List.Item>
        )}
      />
    </ListWrapper>
  );
};

export default CollectionList;
