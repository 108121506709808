import React from "react";
import { Card, Avatar, Modal, message } from "antd";
import { grey, orange, green, blue } from "@ant-design/colors";
import {
  EditOutlined,
  DeleteOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IoApertureSharp } from "react-icons/io5";
import Tooltip from "../../shared/Tooltip";
import { CollectionProps } from ".";
import { deleteNFT } from "../../api";
import { ImgWrapper } from "./styles";

export enum NFT_STATUS {
  NOT_MINTED = "NOT_MINTED",
  MINTED = "MINTED",
  CLAIMABLE = "CLAIMABLE",
  CLAIMED = "CLAIMED",
}

export interface NFTAttributes {
  trait_type: string;
  value: string;
}

export interface NFTProps {
  id: number;
  name: string;
  description?: string;
  mediaUrl: string;
  owner: string;
  contract: string;
  status: NFT_STATUS;
  attributes?: NFTAttributes[];
  copyId?: number;
  collectionId: number;
  coverPhoto?: string;
  tokenId?: number;
}

const STATUS_TO_COLOR_MAP = {
  [NFT_STATUS.NOT_MINTED]: grey.primary,
  [NFT_STATUS.MINTED]: orange.primary,
  [NFT_STATUS.CLAIMABLE]: blue.primary,
  [NFT_STATUS.CLAIMED]: green.primary,
};

export interface NFTBoxProps extends NFTProps {
  collection: CollectionProps;
  fetchCollection: (id: number) => void;
}

const NFTBox: React.FC<NFTBoxProps> = ({
  collection,
  fetchCollection,
  ...nft
}) => {
  const { id, name, mediaUrl, description, collectionId, status } = nft;
  const history = useHistory();

  const onDelete = (id: number): void => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      content: "You can only delete an NFT if it hasn't been minted already",
      okText: "Delete",
      cancelText: "No",
      okType: "danger",
      onOk: async () => {
        await deleteNFT(id);
        await fetchCollection(collection.id);
        history.push(`/collections/${collection.id}`);
        message.success("NFT deleted!");
      },
    });
  };

  return (
    <div>
      <Card
        cover={<ImgWrapper alt={name} src={mediaUrl} />}
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined
            key="edit"
            onClick={() =>
              history.push({
                pathname: `/collections/${collectionId}/edit/${id}`,
                state: { nft, collection },
              })
            }
          />,
          <DeleteOutlined
            key="delete"
            style={{ color: "red" }}
            onClick={() => onDelete(id)}
          />,
        ]}
        hoverable
      >
        <Card.Meta
          avatar={
            <Tooltip text={`Status: ${status}`}>
              <Avatar
                src={
                  <IoApertureSharp
                    color={STATUS_TO_COLOR_MAP[status]}
                    size="large"
                  />
                }
              />
            </Tooltip>
          }
          title={name}
          description={description}
        />
      </Card>
    </div>
  );
};

export default NFTBox;
