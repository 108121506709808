import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import { GlobalProvider } from "./context/GlobalState";
import ModalProvider from "./context/ModalProvider";
import GlobalStyles from "./globalStyles";
import { ProvideAuth } from "./hooks/useAuth";
import AuthGate from "./shared/AuthGate";
import history from "./shared/history";

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ProvideAuth>
        <AuthGate>
          <GlobalProvider>
            <ModalProvider>
              <GlobalStyles />
              <App />
            </ModalProvider>
          </GlobalProvider>
        </AuthGate>
      </ProvideAuth>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);
