import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getCollection } from "../../api";
import { GlobalContext } from "../../context/GlobalState";
import ActionsBar from "../../shared/ActionBar";
import { onDelete, onPublish, onSchedule, onDrop } from "../../shared/actions";
import PageHeader from "../../shared/PageHeader";
import NFTList from "../../shared/NFTList";
import {
  ACTION_BAR_TYPES,
  COLLECTION_STATES,
  DATE_FORMAT,
  MODAL_NAMES,
  TIME_FORMAT,
  COLORS,
} from "../../shared/constants";
import applyFilters from "../../shared/Filter/applyFilters";
import applySearch from "../../shared/Search/applySearch";
import applySort from "../../shared/Sort/applySort";
import { PublishTag } from "../HomePage/styles";
import Actions from "./Actions";
import {
  ActionButton,
  GalleryWrapper,
  PolygonscanButton,
  Wrapper,
} from "./styles";
import Tooltip from "../../shared/Tooltip";
import { NFTProps, NFT_STATUS } from "./NFTBox";
import _uniqBy from "lodash/uniqBy";

export interface CollectionProps {
  id: number;
  name: string;
  logo?: string;
  symbol: string;
  claim_code: string;
  backgroundImage?: string;
  description?: string;
  address?: string;
  drop_scheduled_at?: string;
  drop_type: string;
  state: COLLECTION_STATES;
  live: boolean;
  created_at: string;
  nfts: any[];
  mintedNumber?: number;
}

export interface ParamsProps {
  id: string;
  nftId?: string;
}

export interface LocationState {
  collection?: CollectionProps;
}

const Collection: React.FC = () => {
  const [data, setData] = useState<CollectionProps>({} as CollectionProps);
  const location = useLocation<LocationState>();
  const params = useParams<ParamsProps>();
  const { openModal, deployMintStatus } = useContext(GlobalContext);
  const history = useHistory();
  const [form] = Form.useForm<FormInstance>();
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("newest");
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const groupNFTsWithCopies = (nfts: NFTProps[]) => {
    const isStack = (nft: NFTProps) =>
      Boolean(
        nft.copyId && nft.copyId > 0 && nft.status === NFT_STATUS.NOT_MINTED
      );

    const uniqueNFTs = _uniqBy(nfts, (el) => {
      if (isStack(el)) {
        return el.copyId;
      }

      return el;
    }).map((el) => {
      if (isStack(el)) {
        const newName = el.name.split(" | ")[0];
        const count = el.name.split(" | ")[1]?.split(" of ")[1];

        const elGrouped = {
          ...el,
          name: `${newName} (${count} copies)`,
        };
        return elGrouped;
      }
      return el;
    });

    return uniqueNFTs;
  };

  const fetchCollection = async (id: string | number) => {
    const data = await getCollection(id);
    if (!data) {
      history.push("/collections");
    } else {
      const nfts = groupNFTsWithCopies(data.nfts);

      setData({ ...data, nfts });
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchCollection(params.id);
  }, [location.state, params.id]);

  const collectionFiltered =
    data.nfts &&
    data.nfts
      .filter((c) => applySearch(c, searchValue))
      .filter((c) => applyFilters(c, filterValue))
      .sort((a, b) => applySort(a, b, sortValue));

  const claim_code = data.claim_code;
  const collectionId = data.id;

  const actions = [
    <div>
      {[
        COLLECTION_STATES.PUBLISHING,
        COLLECTION_STATES.PUBLISHED,
        COLLECTION_STATES.DROPPED,
      ].includes(data.state)
        ? [
            <PolygonscanButton
              shape="round"
              size="large"
              href={`https://mumbai.polygonscan.com/address/${
                deployMintStatus[data.id]?.address || data.address
              }`}
              target="_blank"
              key={data.id}
            >
              Polygonscan
              <BsArrowUpRight />
            </PolygonscanButton>,

            deployMintStatus[data.id]?.mintComplete && [
              COLLECTION_STATES.DROPPED !== data.state && [
                <ActionButton
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() =>
                    onSchedule(data, form, () => fetchCollection(data.id))
                  }
                  key={data.id}
                >
                  {!data.drop_scheduled_at
                    ? "Schedule Drop"
                    : "Edit Scheduled Date"}
                </ActionButton>,

                data.drop_type !== "AUTO" && (
                  <ActionButton
                    shape="round"
                    size="large"
                    onClick={() =>
                      onDrop(data.id, () => fetchCollection(data.id))
                    }
                    key={data.id}
                  >
                    Drop
                  </ActionButton>
                ),
              ],
            ],
          ]
        : [
            <ActionButton
              type="default"
              shape="round"
              size="large"
              onClick={() =>
                history.push({
                  pathname: `/collections/${data.id}/edit`,
                  state: { collection: data },
                })
              }
              key={data.id}
            >
              Edit
            </ActionButton>,
            <ActionButton
              shape="round"
              size="large"
              danger
              onClick={() =>
                onDelete(data.id, history, () => fetchCollection(data.id))
              }
              key={data.id}
            >
              Delete
            </ActionButton>,
            <ActionButton
              type="default"
              shape="round"
              size="large"
              onClick={() => onPublish(data.id, () => fetchCollection(data.id))}
              key={data.id}
            >
              Publish
            </ActionButton>,
          ]}
      <ActionButton
        shape="round"
        size="large"
        onClick={() => {
          history.push({
            pathname: `/collections`,
          });
        }}
      >
        Back
      </ActionButton>
    </div>,
  ];

  const tags = [
    <PublishTag
      visible={
        deployMintStatus[data.id]?.mintComplete &&
        COLLECTION_STATES.DROPPED !== data.state
      }
      color={COLORS.green}
      icon={<CheckCircleOutlined />}
      key={data.state}
    >
      published
    </PublishTag>,
    <PublishTag
      visible={COLLECTION_STATES.DROPPED === data.state}
      color={COLORS.green}
      icon={<CheckCircleOutlined />}
      key={data.state}
    >
      dropped
    </PublishTag>,
    <PublishTag
      visible={
        !deployMintStatus[data.id]?.mintComplete &&
        COLLECTION_STATES.PUBLISHING === data.state
      }
      color={COLORS.orange}
      icon={<CheckCircleOutlined />}
      key={data.state}
    >
      publishing
    </PublishTag>,
    <Tooltip
      text={`scheduled for ${moment(data.drop_scheduled_at).format(
        `${DATE_FORMAT}, ${TIME_FORMAT}`
      )}`}
      key={data.state}
    >
      <PublishTag
        visible={
          Boolean(data.drop_scheduled_at) &&
          COLLECTION_STATES.DROPPED !== data.state
        }
        color={COLORS.orange}
        icon={<ClockCircleOutlined />}
        key={data.state}
      >
        scheduled
      </PublishTag>
    </Tooltip>,
  ];

  const onItemClick = (nft: any) => {
    openModal(MODAL_NAMES.VIEW_NFT, {
      ...nft,
      campaignName: data.name,
      campaign: data,
    });
  };

  if (!dataLoaded) {
    return null;
  }

  return (
    <Wrapper>
      <PageHeader
        title={data.name}
        description={data.description}
        logoUrl={data.logo}
        claim_code={data.claim_code}
        actions={actions}
        tags={tags}
      />
      <ActionsBar
        type={ACTION_BAR_TYPES.NFTS}
        item={data}
        {...{
          searchValue,
          setSearchValue,
          sortValue,
          setSortValue,
          filterValue,
          setFilterValue,
          claim_code,
          collectionId,
        }}
      />
      <Actions collection={data} />

      <GalleryWrapper gutter={24} align="stretch">
        <NFTList
          campaign={{ ...data, nfts: collectionFiltered }}
          onItemClick={onItemClick}
        />
      </GalleryWrapper>
    </Wrapper>
  );
};

export default Collection;
