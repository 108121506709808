import { Button } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const LeftBackgroundBox = styled.div`
  background-color: azure;
`;

export const RightBackgroundBox = styled.div`
  background-color: var(--color-secondary);
`;

export const FormWrapper = styled.div`
  position: absolute;
  background-color: white;
  width: 500px;
  height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%);
  padding: 4rem;
`;

export const ForgotPasswordButton = styled(Button)`
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 0;
  padding-left: 0;
`;

export const Title = styled.h1`
  margin: 0 0 2rem 0;
`;