import { message } from "antd";
import axios from "../shared/axios";
import { useState, useContext, createContext } from "react";
import { parseErrorMessage } from "../shared/helpers";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = async (userData) => {
    try {
      const { data } = await axios.post(`/signin`, userData);

      localStorage.setItem("token", data.token);
      setUser(true);

      message.success("Successfully logged in!");
    } catch (err) {
      message.error(parseErrorMessage(err));
      setUser(false);
      return;
    }
  };

  const signout = async () => {
    try {
      await axios.get(`/signout`);
      localStorage.removeItem("token");
      setUser(false);
    } catch (err) {
      message.error(parseErrorMessage(err));
      return;
    }
  };

  return {
    user,
    signin,
    signout,
    setUser,
  };
}
