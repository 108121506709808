import { message } from "antd";

import axios from "../shared/axios";
import { parseErrorMessage } from "../shared/helpers";
import { CollectionProps } from "../views/CollectionPage";
import { NFTProps } from "../views/CollectionPage/NFTBox";

export const getCollections = async () => {
  try {
    const response = await axios.get("/collections");
    return response.data as CollectionProps[];
  } catch (err) {
    message.error(parseErrorMessage(err));
    return [];
  }
};

export const getCollection = async (id: string | number) => {
  try {
    const response = await axios.get(`/collections/${id}`);
    return response.data as CollectionProps;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const createCollection = async (data: Partial<CollectionProps>) => {
  try {
    const response = await axios.post("/collections", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const editCollection = async (
  id: string | number,
  data: Partial<CollectionProps>
) => {
  try {
    const response = await axios.put(`/collections/${id}`, data);
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const dropCollection = async (id: number) => {
  try {
    const response = await axios.post(`/collections/${id}/drop`);
    return response;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const deleteCollection = async (id: number) => {
  try {
    const response = await axios.delete(`/collections/${id}`);
    return response.data as string;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const publishCollection = async (id: number) => {
  try {
    const response = await axios.post(`/publish`, { id });
    return response.data as string;
  } catch (err) {
    message.error(parseErrorMessage(err));
    throw "";
  }
};

export const createNFT = async (data: Partial<NFTProps>) => {
  try {
    const response = await axios.post("/nfts", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const createNFTCover = async (data: Partial<NFTProps>) => {
  try {
    const response = await axios.post("/nftsCover", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const editNFT = async (id: string, data: Partial<NFTProps>) => {
  try {
    const response = await axios.put(`/nfts/${id}`, data);
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const editNFTCover = async (id: string, data: Partial<NFTProps>) => {
  try {
    const response = await axios.put(`/nftsCover/${id}`, data);
    return response.data as number;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const deleteNFT = async (id: number) => {
  try {
    const response = await axios.delete(`/nfts/${id}`);
    return response.data as string;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const checkIfEverythingIsMinted = async (id: string | number) => {
  try {
    const response = await axios.get(`/publish/${id}`);
    return response.data as
      | string
      | { totalNumber: number; mintedNumber: number };
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};

export const goLiveOrUnlive = async (id: number) => {
  try {
    const response = await axios.put(`/collections/${id}/golive`);
    return response.data;
  } catch (err) {
    message.error(parseErrorMessage(err));
    return;
  }
};
