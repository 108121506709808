import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Wrapper } from "./styles";

export interface SearchProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const Search: React.FC<SearchProps> = ({ value, onChange, placeholder }) => {
  const onChangeHandler = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <Wrapper>
      <Input value={value} prefix={<SearchOutlined />} placeholder={placeholder} onChange={onChangeHandler} allowClear />
    </Wrapper>
  );
};

export default Search;
