import { CollectionProps } from "../views/CollectionPage";
import { NFTProps } from "../views/CollectionPage/NFTBox";
import { LoginFormProps } from "../views/Login";

export interface Error {
  status?: number;
  message?: string;
  response: {
    data: string | CollectionProps | NFTProps;
    statusText: string;
  };
}

export interface AuthProps {
  user: string | boolean;
  signin: (data: LoginFormProps) => void;
  signout: () => void;
  setUser: (user: boolean) => void;
}

export const parseErrorMessage = (err: Error | any) => {
  return err.response.data || err.message || "There was an error";
}

export const getImageFromBase64 = (img: Blob, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
