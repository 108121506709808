import { Modal } from "antd";
import styled from "styled-components";

export const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding: 1rem;
  }

  .ant-modal-content {
    border-radius: 0.5rem;
  }
`;

export const ListImage = styled.img`
  width: 100%;
  object-fit: cover;
  border: 1px solid var(--color-primary-alt-2);
  border-radius: 0.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin-top: 1rem;

  .ant-divider {
    border-top: 1px solid var(--color-primary-alt-3);
    margin: 1rem 0;
  }

  button {
    margin-bottom: 0.75rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.875rem;
  letter-spacing: 0em;
`;

export const Description = styled.div`
  margin-bottom: 1.125rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  max-width: 320px;
`;

export const Info = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-bottom: 0.75rem;

  div:first-child {
    color: var(--color-primary);
  }

  div:last-child {
    color: var(--color-black);
    margin-left: 0.25rem;
  }
`;

export const SellBoxWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 20rem;
`;

export const SellBoxDescription = styled.div`
  display: inline-flex;
  align-items: center;
  
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  strong {
    font-weight: 800;
    margin-left: 0.5rem;
  }
`;

export const Price = styled.div`
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 4.25rem;
  letter-spacing: 0em;
  text-align: center;
`;
export const Currency = styled.div`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8125rem;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-primary);
`;
