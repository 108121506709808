import { Select } from "antd";
import { Wrapper } from "./styles";

export interface SortProps {
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
}

const Sort: React.FC<SortProps> = ({ value, onChange, options, placeholder }) => {
  return (
    <Wrapper>
      <div>Sort by</div>
      <Select onChange={onChange} value={value}>
        {options.map((o) => (
          <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default Sort;
