import { Col, Row } from "antd";
import React, { ReactElement, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Wrapper } from "./styles";

export interface HeaderProps {
  title: string;
  subtitle?: string;
  actions?: ReactNode[];
  withBackArrow?: boolean;
  description?: string;
  tags?: ReactElement | ReactElement[];
}

const Header: React.FC<HeaderProps> = ({ title, actions, tags, withBackArrow, subtitle, description }) => {
  const history = useHistory();

  return (
    <Wrapper title={title} subTitle={subtitle} extra={actions} tags={tags} ghost={false} {...(withBackArrow && { onBack: history.goBack })}>
      <Row gutter={[12, 12]}>
        <Col span={24}>{description}</Col>
      </Row>
    </Wrapper>
  );
};

export default Header;
