import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../shared/Header";
import ActionsBar from "../../shared/ActionBar";
import CollectionList from "./CollectionList";
import { Wrapper } from "./styles";
import applyFilters from "../../shared/Filter/applyFilters";
import applySort from "../../shared/Sort/applySort";
import applySearch from "../../shared/Search/applySearch";
import { ACTION_BAR_TYPES } from "../../shared/constants";
import { GlobalContext } from "../../context/GlobalState";

const Home: React.FC = () => {
  const { collections, getCollections } = useContext(GlobalContext);

  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("dropDate");
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    getCollections();
  }, []);

  const actions = [
    <Button
      type="primary"
      shape="round"
      size="large"
      onClick={() => history.push("/collections/create")}
    >
      Create
    </Button>,
  ];

  const collectionsFiltered = collections
    .filter((c) => applySearch(c, searchValue))
    .filter((c) => applyFilters(c, filterValue))
    .sort((a, b) => applySort(a, b, sortValue));

  return (
    <Wrapper>
      <Header title="Collections" actions={actions} />
      <ActionsBar
        collectionId={0}
        type={ACTION_BAR_TYPES.COLLECTION}
        filterMode="multiple"
        {...{
          searchValue,
          setSearchValue,
          sortValue,
          setSortValue,
          filterValue,
          setFilterValue,
        }}
      />

      <CollectionList collections={collectionsFiltered} />
    </Wrapper>
  );
};

export default Home;
