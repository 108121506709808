import React, { ReactNode } from "react";
import { ButtonProps } from "antd";
import {
  ClaimTag,
  Content,
  Description,
  Extra,
  Image,
  Tags,
  Title,
  Wrapper,
} from "./styles";
import Tooltip from "../Tooltip";
import { COLORS } from "../constants";

interface PageHeaderProps {
  title: string;
  description?: string;
  logoUrl?: string;
  claim_code: string;
  extra?: ReactNode;
  tags?: ReactNode | ReactNode[];
  actions?: ButtonProps | ButtonProps[];
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  logoUrl,
  claim_code,
  extra,
  actions,
  tags,
}) => {
  const allTags = [
    <Tooltip text="This code will be used to claim the drop" key="claimTag">
      <ClaimTag color={COLORS.geekblue}>
        <span>claim code:</span>
        <span>{claim_code}</span>
      </ClaimTag>
    </Tooltip>,
    tags,
  ];

  return (
    <Wrapper>
      {logoUrl && <Image src={logoUrl} alt="logo" />}

      <Content>
        <Title>
          {title}
          {extra && <Extra>{extra}</Extra>}
        </Title>
        <div>{actions}</div>
        <Description>{description}</Description>
        <Tags>{allTags}</Tags>
      </Content>
    </Wrapper>
  );
};

export default PageHeader;
