import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { createNFT, createNFTCover, editNFT, editNFTCover } from "../../api";
import { CollectionProps } from "../CollectionPage";
import Header from "../../shared/Header";
import {
  Wrapper,
  FormWrapper,
  SelectImg,
  SelectWrapper,
  PropertiesContent,
  TraitBox,
  MediaPreviewImg,
  MediaPreviewVideo,
  AtributeValue,
  TraitType,
  MakeCopiesWrapper,
  FormItemMakeCopies,
} from "./styles";
import { NFTAttributes, NFTProps } from "../CollectionPage/NFTBox";
import { getImageFromBase64 } from "../../shared/helpers";
import AddEditProperties from "./AddEditProperties";
import { Media } from "minze_components_lib";

const SUPPORTED_IMAGE_EXTENSIONS = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/svg",
  "image/svg+xml",
];

const SUPPORTED_VIDEO_AND_AUDIO_EXTENSIONS = [
  "video/mp4",
  "video/m4a",
  "video/quicktime",
  "video/wmv",
  "video/mpeg",
  "video/webm",
  "video/ogg",
  "video/3gpp",
  "video/m4p",
  "audio/mpeg",
  "audio/mp3",
  "audio/x-m4a",
  "audio/flac",
  "audio/3gpp",
  "audio/wav",
  "audio/webm",
  "audio/aac",
];

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

interface AddOrEditNFTProps {
  edit?: boolean;
}

interface LocationState {
  collection: CollectionProps;
  nft?: NFTProps;
}

interface ParamsProps {
  id: string;
  nftId: string;
}

const AddOrEditNFT: React.FC<AddOrEditNFTProps> = ({ edit }) => {
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const params = useParams<ParamsProps>();

  const collection = location.state?.collection;
  const nft = location.state?.nft;
  const [traitsModalVisible, setTraitsModalVisible] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(edit ? nft?.mediaUrl : "");
  const [attributes, setAttributes] = useState(nft?.attributes ?? []);
  const [makeCopies, setMakeCopies] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState(edit ? nft?.coverPhoto : "");
  const [coverPhotoOption, setCoverPhotoOption] = useState(
    edit
      ? /\.(mp4|m4a|quicktime|wmv|mpeg|webm|ogg|3gpp|m4p|mpeg|mp3|x-m4a|flac|3gpp|wav|webm|aac|)$/.test(
          mediaUrl as any
        ) && true
      : false
  );

  const traitsTypeOptions: { value: string }[] = [
    ...Array.from(
      new Set(
        collection?.nfts
          ?.map((nft: NFTProps) =>
            nft.attributes?.map((attribute: any) => attribute.trait_type)
          )
          .flat()
      )
    ).map((value) => ({
      value,
    })),
  ];

  const actions = [
    <Button
      type="primary"
      shape="round"
      size="large"
      onClick={() => history.push(`/collections/${params.id}`)}
    >
      Back to Collection
    </Button>,
    <Button
      shape="round"
      size="large"
      onClick={() => history.push("/collections")}
    >
      All Collections
    </Button>,
  ];

  const onFinish = async (values: any) => {
    const formData: any = new FormData();

    formData.append("name", values.name);
    formData.append("mediaUrl", values.mediaUrl);
    if (coverPhotoOption && coverPhoto) {
      formData.append("coverPhoto", values.coverPhoto);
    }
    formData.append("collectionId", collection.id);

    if (values.description) formData.append("description", values.description);
    if (attributes) formData.append("attributes", JSON.stringify(attributes));
    if (makeCopies)
      formData.append("numberOfCopies", parseInt(values.numberOfCopies));

    setFormLoading(true);

    const id = edit
      ? coverPhotoOption
        ? await editNFTCover(params.nftId, formData)
        : await editNFT(params.nftId, formData)
      : coverPhotoOption
      ? await createNFTCover(formData)
      : await createNFT(formData);

    if (id) {
      message.success(`NFT ${edit ? "edited" : "added"}!`);
      history.push(`/collections/${params.id}`);
    }

    setFormLoading(false);
  };

  const handleChange = (info: any) => {
    setMediaUrl(undefined);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      const mediaUrl = URL.createObjectURL(info.file.originFileObj);
      setLoading(false);
      setMediaUrl(mediaUrl);
    }
  };

  function beforeUpload(file: File) {
    const isSupported =
      SUPPORTED_IMAGE_EXTENSIONS.includes(file.type) ||
      SUPPORTED_VIDEO_AND_AUDIO_EXTENSIONS.includes(file.type);
    if (!isSupported) {
      message.error("File type not supported!");
    }
    const isLt2M = file.size / 1024 / 1024 < 40;
    if (!isLt2M) {
      message.error("Media must smaller than 40MB!");
    }

    if (SUPPORTED_VIDEO_AND_AUDIO_EXTENSIONS.includes(file.type)) {
      setCoverPhotoOption(true);
    } else {
      setCoverPhotoOption(false);
      setCoverPhoto("");
    }

    return isSupported && isLt2M;
  }

  const handleCoverPhotoChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getImageFromBase64(info.file.originFileObj, (coverPhoto: string) => {
        setLoading(false);
        setCoverPhoto(coverPhoto);
      });
    }
  };

  function beforeUploadCoverPhoto(file: File) {
    const isSupported = SUPPORTED_IMAGE_EXTENSIONS.includes(file.type);
    if (!isSupported) {
      message.error("Only images are supported!");
    }

    const isLt2M = file.size / 1024 / 1024 < 40;
    if (!isLt2M) {
      message.error("Image must smaller than 40MB!");
    }
    return isSupported && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Wrapper>
      <Header
        title={`${edit ? "Edit" : "Add"} your NFT`}
        actions={actions}
        withBackArrow
      />

      <FormWrapper
        name="add_nft"
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={{
          ...(nft && {
            name: nft.copyId
              ? nft.name.substring(0, nft.name.indexOf(" | #"))
              : nft.name,
            description: nft.description,
            mediaUrl: nft.mediaUrl,
            coverPhoto: nft.coverPhoto,
          }),
          ...(collection && {
            collection: collection.name,
          }),
        }}
      >
        <Form.Item
          name="mediaUrl"
          label="Image, Video, Audio"
          valuePropName="file"
          extra="File types supported: JPG, JPEG, PNG, GIF, SVG, MP4, M4A, MOV, WMV, MPEG, WEBM, OGG, M4P, MP3, M4A, FLAC, WAV, AAC. Max size: 40 MB ; To get the cover photo option, please upload a new video or audio file"
          getValueFromEvent={(e) => e && e.file.originFileObj}
          rules={[{ required: !edit, message: "Please upload media" }]}
        >
          <Upload
            name="media"
            listType="picture-card"
            className="media-uploader"
            showUploadList={false}
            maxCount={1}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {mediaUrl ? (
              coverPhotoOption ? (
                <MediaPreviewVideo id="mediaUrlVideo" controls>
                  <source src={mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </MediaPreviewVideo>
              ) : (
                <MediaPreviewImg src={mediaUrl} />
              )
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        {coverPhotoOption && (
          <Form.Item
            name="coverPhoto"
            label="Cover image"
            valuePropName="file"
            extra="File types supported: JPG, JPEG, PNG ; if you want to remove the cover image, change the *Image, Audio, Video* field"
            getValueFromEvent={(e) => e && e.file.originFileObj}
          >
            <Upload
              name="mediaCover"
              listType="picture-card"
              className="media-uploader"
              showUploadList={false}
              maxCount={1}
              customRequest={dummyRequest}
              beforeUpload={beforeUploadCoverPhoto}
              onChange={handleCoverPhotoChange}
            >
              {coverPhoto ? <MediaPreviewImg src={coverPhoto} /> : uploadButton}
            </Upload>
          </Form.Item>
        )}

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name" }]}
        >
          <Input placeholder="CryptoPunk #0152" />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            placeholder="The CryptoPunk is a rare collectible..."
            showCount
            maxLength={1000}
          />
        </Form.Item>

        {!edit && (
          <FormItemMakeCopies
            label="Make Copies?"
            name="makeCopies"
            tooltip="Generate multiple copies of the same NFT"
            extra="Enter the number of copies to be generated"
          >
            <MakeCopiesWrapper>
              <Switch
                checkedChildren="YES"
                unCheckedChildren="NO"
                onChange={(checked) => setMakeCopies(checked)}
              />
              <Form.Item
                noStyle
                name="numberOfCopies"
                rules={[
                  {
                    required: makeCopies,
                    message: "Please input number of copies",
                  },
                  {
                    validator: (_, value, callback) => {
                      if (value > 1000 || value < 1) {
                        return callback(
                          "Number of copies must be between 1 and 1000"
                        );
                      }
                      return callback();
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder={makeCopies ? "100" : ""}
                  disabled={!makeCopies}
                />
              </Form.Item>
            </MakeCopiesWrapper>
          </FormItemMakeCopies>
        )}

        <Form.Item
          label="Properties"
          name="attributes"
          extra="Textual traits that differentiate each NFT"
        >
          <PropertiesContent>
            <Row>
              <Col span={24}>
                <Button
                  icon={<PlusOutlined />}
                  type="dashed"
                  size="large"
                  onClick={() => setTraitsModalVisible(true)}
                >
                  Add Trait
                </Button>
              </Col>
            </Row>
            <Row>
              {attributes.map(({ trait_type, value }) =>
                trait_type === "Series" ? (
                  <TraitBox key={trait_type}>
                    <TraitType>{trait_type}</TraitType>
                    <AtributeValue>{value}</AtributeValue>
                  </TraitBox>
                ) : (
                  <TraitBox
                    key={trait_type}
                    onClick={() => setTraitsModalVisible(true)}
                  >
                    <TraitType>{trait_type}</TraitType>
                    <AtributeValue>{value}</AtributeValue>
                  </TraitBox>
                )
              )}
            </Row>
          </PropertiesContent>
        </Form.Item>

        {collection && (
          <Form.Item label="Collection" name="collection">
            <Select open={false}>
              <Select.Option value={collection.name}>
                <SelectWrapper>
                  <SelectImg src={collection.logo} alt={collection.name} />
                  <span>{collection.name}</span>
                </SelectWrapper>
              </Select.Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            loading={formLoading}
          >
            {edit ? "Save" : "Create"}
          </Button>
        </Form.Item>
      </FormWrapper>

      <AddEditProperties
        visible={traitsModalVisible}
        attributes={attributes}
        setAttributes={setAttributes}
        onClose={() => setTraitsModalVisible(false)}
        traitsTypeOptions={traitsTypeOptions}
      />
    </Wrapper>
  );
};

export default AddOrEditNFT;
