import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Divider, message, Modal, Row } from "antd";
import { Button } from "minze_components_lib";
import { useContext } from "react";
import { useHistory } from "react-router";
import { deleteNFT } from "../../../api";
import { GlobalContext } from "../../../context/GlobalState";
import { CollectionProps } from "../../CollectionPage";
import { NFTBoxProps, NFT_STATUS } from "../../CollectionPage/NFTBox";
import { MediaPreview } from "../../CreateOrEditNFTPage/styles";
import { ContentWrapper, Description, Info, ListImage, Title } from "./styles";
import { COLLECTION_STATES, TESTNET_OPENSEA } from "../../../shared/constants";

interface InfoBoxProps extends NFTBoxProps {
  setSellBack: (flag: boolean) => void;
  showActions?: boolean;
  campaignName: string;
  mediaUrl: string;
  campaign: CollectionProps;
}

const InfoBox: React.FC<InfoBoxProps> = ({ campaign, ...nft }) => {
  const {
    id,
    mediaUrl,
    collectionId,
    name,
    campaignName,
    status,
    description,
    tokenId,
  } = nft;

  const history = useHistory();
  const { getCollections, closeModal, deployMintStatus } =
    useContext(GlobalContext);

  const onDelete = (id: number): void => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      content: "You can only delete an NFT if it hasn't been minted already",
      okText: "Delete",
      cancelText: "No",
      okType: "danger",
      onOk: async () => {
        await deleteNFT(id);
        await getCollections();
        closeModal();
        history.go(0);
        message.success("NFT deleted!");
      },
    });
  };

  const showMinted =
    deployMintStatus[collectionId]?.nfts?.[id]?.minted &&
    status === NFT_STATUS.NOT_MINTED;
  const notDeployed =
    !deployMintStatus[collectionId]?.deployed ||
    campaign.state === COLLECTION_STATES.NOT_PUBLISHED;
  const isMinted = deployMintStatus[collectionId]?.nfts?.[id]?.minted;
  const openSeaUrl = `${TESTNET_OPENSEA}/${
    deployMintStatus[collectionId]?.address || campaign.address
  }/${tokenId}`;

  return (
    <Row gutter={16}>
      <Col flex="416px">
        <MediaPreview mediaUrl={mediaUrl} name={name} />
      </Col>
      <Col flex="auto">
        <ContentWrapper>
          <Title>{name}</Title>
          <Divider />
          <Info>
            <div>Campaign:</div>
            <div>{campaignName}</div>
          </Info>
          <Info>
            <div>Status:</div>
            <div>{showMinted ? NFT_STATUS.MINTED : status}</div>
          </Info>
          <Description>
            {description && description.slice(0, 100).concat("...")}
          </Description>

          {notDeployed ? (
            <>
              <Button
                block
                onClick={() => {
                  history.push({
                    pathname: `/collections/${collectionId}/edit/${id}`,
                    state: { nft, collection: campaign },
                  });
                  closeModal();
                }}
              >
                Edit
              </Button>
              <Button block inverted onClick={() => onDelete(id)}>
                Delete
              </Button>
            </>
          ) : isMinted ? (
            <>
              <Button
                block
                onClick={() =>
                  window.open(openSeaUrl, "_blank", "noopener noreferrer")
                }
              >
                View on OpenSea
              </Button>
            </>
          ) : null}
        </ContentWrapper>
      </Col>
    </Row>
  );
};

export default InfoBox;
