import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0rem 2rem;
  width: 100%;

  && {
    .ant-list-item {
      width: 100%;
    }
  }
`;

export const ListImageLarge = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: none;
`;

export const NFTCard = styled.div<{ $stack: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--color-primary-alt-1);
  border: 1px solid var(--color-primary-alt-2);
  border-radius: 0.5rem;
  height: 100%;
  cursor: pointer;

  div:first-child {
    border-bottom: 1px solid var(--color-primary-alt-2);
    padding: 1rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
  }

  ${({ $stack }) =>
    $stack &&
    `
      margin: 1rem;
      height: calc(100% - 2rem);
  `}
`;

export const StackedCard = styled.div<{ deg: number }>`
  position: absolute;
  inset: 1rem;
  z-index: -1;
  background-color: var(--color-primary-alt-1);
  border-radius: 0.5rem;
  border: 1px solid var(--color-primary-alt-2);
  transform: ${({ deg }) => `rotate(${deg}deg)`};
`;
