import { Form } from "antd";
import { Media } from "minze_components_lib";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
`;

export const FormWrapper = styled(Form)`
  margin: 2rem;

  && {
    .ant-form-item-label > label {
      font-size: 1rem;
      font-weight: bold;
    }

    .ant-upload.ant-upload-select-picture-card {
      width: 13rem;
      height: 13rem;
    }
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectImg = styled.img`
  height: 1.5rem;
  width: auto;
  margin-right: 0.5rem;
`;

export const PropertiesContent = styled.div`
  margin-bottom: 1rem;

  .ant-row {
    margin-bottom: 1rem;
  }
`;

export const TraitBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 170px;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--color-secondary) 10;
  margin-right: 2rem;

  & > div {
    flex: 100%;
  }

  div:nth-child(1) {
    font-weight: bold;
    color: var(--color-secondary);
    position: relative;
    top: 10px;
  }

  div:nth-child(2) {
    font-weight: bold;
  }
`;

export const TraitType = styled.div`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AtributeValue = styled.div`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    max-height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
  }
`;

export const MediaPreview = styled(Media)`
  && {
    max-height: 100%;
    object-fit: contain;
  }
`;

export const MediaPreviewImg = styled.img`
  && {
    max-height: 100%;
    object-fit: contain;
  }
`;

export const MediaPreviewVideo = styled.video`
  && {
    max-height: 100%;
    object-fit: contain;
  }
`;

export const FormItemMakeCopies = styled(Form.Item)`
  && {
    .ant-form-item-extra,
    div[role="alert"] {
      padding-left: 86px;
    }
  }
`;

export const MakeCopiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  align-items: center;
  gap: 2rem;
`;
