import { Tag } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
  align-items: flex-start;
  padding: 4rem 2rem 3rem 2rem;
`;

export const Image = styled.img`
  width: 4.25rem;
  height: 4.25rem;
  object-fit: cover;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  column-gap: 1.5rem;
  row-gap: 1rem;
`;

export const Title = styled.h1`
  display: flex;
  align-items: flex-end;
  color: var(--color-black);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 4.25rem;
  letter-spacing: 0em;
  margin: 0;
`;

export const Description = styled.div`
  grid-column: 1 / 3;
  color: var(--color-primary);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0em;
`;

export const Extra = styled.div`
  color: var(--color-tertiary);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: right;
  margin-left: 1.5rem;
  position: relative;
  bottom: 0.25rem;
`;

export const Tags = styled.div`
  grid-column: 1 / 3;
  line-height: 1.75rem;
  letter-spacing: 0em;
`;

export const ClaimTag = styled(Tag)`
  font-weight: normal;
  margin-right: 1rem;

  span:last-child {
    margin-left: 0.25rem;
    font-weight: bold;
  }
`;
