import { Tag } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
`;

export const ListWrapper = styled.div`
  display: grid;
`;

export const PublishTag = styled(Tag)`
  margin-right: 1rem;
  font-size: 0.7rem;
`;

export const CollectionImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 180px;
`;

export const ListExtraWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  div:nth-child(2n - 1) {
    font-weight: 500;
    color: var(--color-primary);
  }

  div:nth-child(2n) {
    margin-right: 1rem;
    margin-left: 0.25rem;
    font-weight: 800;
    line-height: 22px;
  }
`;

export const ListTitle = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;