import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
  align-items: center;
  
  && {
    .ant-select-selector {
      width: 100%;
      min-width: 150px;
      border-radius: 5px;
    }
  }

  & > * + * {
    margin-left: 1rem;
  }
`;