import { createGlobalStyle } from "styled-components";
import theme from "./theme";

export default createGlobalStyle`
  :root {
    --color-primary: ${theme.color.gray};
    --color-primary-alt-1: ${theme.color.gray2};
    --color-primary-alt-2: ${theme.color.gray3};
    --color-primary-alt-3: ${theme.color.gray4};
    --color-primary-alt-4: ${theme.color.gray5};
    --color-primary-alt-5: ${theme.color.gray6};
    --color-primary-alt-6: ${theme.color.gray7};
    --color-primary-alt-7: ${theme.color.gray8};
    --color-secondary: ${theme.color.blue};
    --color-secondary-alt-1: ${theme.color.blue2};
    --color-tertiary: ${theme.color.red};
    --color-black: ${theme.color.black};
    --color-black-alt-1: ${theme.color.black2};
    --color-black-alt-2: ${theme.color.black3};
    --color-white: ${theme.color.white};
    --color-white-alt-1: ${theme.color.white};
  }

  body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;
