import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { ForgotPasswordButton, FormWrapper, LeftBackgroundBox, RightBackgroundBox, Wrapper, Title } from "./styles";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/useAuth";
import { AuthProps } from "../../shared/helpers";
import { useHistory, useLocation } from "react-router";

export interface LoginFormProps {
  username: string;
  password: string;
  remember?: boolean;
}

interface LocationState {
  state?: {
    logout: boolean;
  }
}

const Login: React.FC = () => {
  const auth: AuthProps = useAuth();
  const location: LocationState = useLocation();

  useEffect(() => {
    if (location.state?.logout) {
      auth.signout();
    }

  }, [location]);

  const onLogin = async (values: LoginFormProps) => {
    auth.signin(values);
  };

  return (
    <Wrapper>
      <LeftBackgroundBox />
      <RightBackgroundBox />

      <FormWrapper>
        <Title>Sign in to your account</Title>

        <Form name="login" layout="vertical" requiredMark="optional" initialValues={{ remember: true }} onFinish={onLogin}>
          <Form.Item name="username" label="Username" rules={[{ required: true, message: "Please input your username!" }]}>
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please input your password!" }]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <ForgotPasswordButton type="link">Forgot password</ForgotPasswordButton>
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
};

export default Login;
