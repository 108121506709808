import React from "react";
import { Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ActionsWrapper } from "./styles";
import { useHistory } from "react-router-dom";
import { CollectionProps } from ".";
import { COLLECTION_STATES } from "../../shared/constants";

interface ActionsProps {
  collection: CollectionProps;
}

const Actions: React.FC<ActionsProps> = ({ collection }) => {
  const history = useHistory();

  if (
    collection.state === COLLECTION_STATES.PUBLISHED ||
    collection.state === COLLECTION_STATES.PUBLISHING ||
    collection.state === COLLECTION_STATES.DROPPED
  ) {
    return null;
  }

  return (
    <ActionsWrapper align="middle">
      <Col span={8}>
        <Button
          icon={<PlusOutlined />}
          type="dashed"
          size="large"
          onClick={() =>
            history.push({
              pathname: `/collections/${collection.id}/add`,
              state: { collection },
            })
          }
        >
          Add NFT
        </Button>
      </Col>
    </ActionsWrapper>
  );
};

export default Actions;
