import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Divider, Button, AutoComplete } from "antd";
import { NFTAttributes } from "../CollectionPage/NFTBox";

interface AddEditPropertiesProps {
  visible: boolean;
  title?: string;
  attributes: NFTAttributes[];
  traitsTypeOptions?: { value: string }[];
  setAttributes: (attributes: NFTAttributes[]) => void;
  onClose: () => void;
}

const AddEditProperties: React.FC<AddEditPropertiesProps> = ({
  visible,
  title = "Add Properties",
  attributes,
  setAttributes,
  onClose,
  traitsTypeOptions,
}) => {
  const [form] = Form.useForm();

  const onOk = async () => {
    await form.validateFields();

    const { attributes } = form.getFieldsValue();

    setAttributes(attributes);
    onClose();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okText="Save"
    >
      <Form form={form}>
        <Form.List name="attributes" initialValue={attributes}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) =>
                  attributes[index]?.trait_type === "Series" ? null : (
                    <div key={field.key}>
                      <Divider>Trait {index + 1}</Divider>
                      <Form.Item
                        name={[index, "trait_type"]}
                        label="Type"
                        rules={[{ required: true }]}
                      >
                        <AutoComplete
                          options={traitsTypeOptions}
                          placeholder="type"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Value"
                        name={[index, "value"]}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="value" />
                      </Form.Item>

                      {fields.length >= 1 ? (
                        <Button
                          danger
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Remove Above Trait
                        </Button>
                      ) : null}
                    </div>
                  )
                )}
                <Divider />
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    size="large"
                    block
                  >
                    <PlusOutlined /> Add Trait
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddEditProperties;
