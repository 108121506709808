import { Row, Button } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 1200px;
  margin: 0 auto;
`;

export const GalleryWrapper = styled(Row)`
  margin: 2rem 0;
`;

export const ImgWrapper = styled.img`
  width: 100%;
  height: 384px;
  object-fit: contain;
  border-bottom: 1px solid #f0f0f0; /* antd border color */
`;

export const ActionsWrapper = styled(Row)`
  margin-top: 2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const PlaceholderButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
`;

export const PolygonscanButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;

  &.ant-btn {
    padding-top: 6.4px !important; // antd overwrite
  }

  svg {
    margin-left: 0.5rem;
  }
`

export const ActionButton = styled(Button)`
  margin-left: 0.5rem;
`;