import React from "react";
import { List } from "antd";
import { Wrapper, NFTCard, ListImageLarge, StackedCard } from "./styles";
import { COLLECTION_STATES } from "../constants";
import { MediaPreview } from "../../views/CreateOrEditNFTPage/styles";
import { NFT_STATUS } from "../../views/CollectionPage/NFTBox";

interface NFTCardProps {
  id: number;
  name: string;
  mediaUrl: string;
  copyId?: number;
  status: NFT_STATUS;
}

interface CampaignProps {
  id: number;
  name: string;
  logo?: string;
  description?: string;
  state: COLLECTION_STATES;
  has_dropped?: boolean;
  nfts: NFTCardProps[];
}

interface NFTListProps {
  campaign: CampaignProps;
  onItemClick: (nft: NFTCardProps) => void;
}

const NFTList: React.FC<NFTListProps> = ({ campaign, onItemClick }) => {
  const isStack = (nft: NFTCardProps) =>
    Boolean(
      nft.copyId && nft.copyId > 0 && nft.status === NFT_STATUS.NOT_MINTED
    );

  return (
    <Wrapper>
      <List
        grid={{ gutter: 16, xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
        dataSource={campaign.nfts}
        renderItem={(nft) => (
          <List.Item
            onClick={() => onItemClick(nft)}
            colStyle={{ display: "flex", height: "100%" }}
          >
            <NFTCard $stack={isStack(nft)}>
              <div>{nft.name}</div>
              <MediaPreview mediaUrl={nft.mediaUrl} name={nft.name} />
              {isStack(nft) && <StackedCard deg={4} />}
            </NFTCard>
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export default NFTList;
