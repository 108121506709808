import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Select, Tag } from "antd";
import { Wrapper } from "./styles";

export interface FilterProps {
  value: string[];
  onChange: (value: string[]) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  mode?: any /* "multiple" | undefined */;
}

const getTagParams = (value: string) => {
  if (value === "published")
    return { color: "green", icon: <ClockCircleOutlined /> };
  if (value === "scheduled")
    return { color: "orange", icon: <CheckCircleOutlined /> };
  if (value === "dropped")
    return { color: "green", icon: <CheckCircleOutlined /> };
  return { color: "default" };
};

const Filter: React.FC<FilterProps> = ({
  value,
  onChange,
  options,
  mode,
  placeholder,
}) => {
  const tagRender = ({ label, value, closable, onClose }: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const { color, icon } = getTagParams(value);

    return (
      <Tag
        color={color}
        icon={icon}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Wrapper>
      <div>Filter by</div>
      <Select
        mode={mode}
        onChange={onChange}
        value={value}
        tagRender={tagRender}
        showArrow
      >
        {options.map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default Filter;
