import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalState";
import { MODAL_NAMES } from "../../../shared/constants";
import { ModalWrapper } from "./styles";
import InfoBox from "./InfoBox";

const ViewNFTModal: React.FC = () => {
  const { modal, closeModal } = useContext(GlobalContext);

  const onClose = () => {
    closeModal();
  };

  if (!modal.name) {
    return <div />;
  }

  const visible = modal.name === MODAL_NAMES.VIEW_NFT;
  const { campaign, ...nft } = modal.data;

  return (
    <ModalWrapper title="" width={768} footer={false} visible={visible} closable={false} onCancel={onClose} destroyOnClose>
      <InfoBox campaign={campaign} {...nft} />
    </ModalWrapper>
  );
};

export default ViewNFTModal;
