import { ReactNode } from "react"
import ViewNFTModal from "../views/Modals/ViewNFTModal";

interface ModalProviderProps {
  children: ReactNode;
}


const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  return (
    <>
    {children}

    <ViewNFTModal />
    </>
  )
}

export default ModalProvider;