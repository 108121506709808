import { COLLECTION_STATES } from "../shared/constants";

export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTION = "GET_COLLECTION";
export const UPDATE_COLLECTION_STATE = "UPDATE_COLLECTION_STATE";
export const UPDATE_NFT_STATE = "UPDATE_NFT_STATE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

const appReducer = (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_COLLECTIONS:
      return {
        ...state,
        collections: action.payload,
      };
    case GET_COLLECTION:
      return {
        ...state,
        singleCollection: action.payload,
      };
    case UPDATE_COLLECTION_STATE:
      const { collectionId: id, address, event: deployEvent } = action.payload;

      return {
        ...state,
        deployMintStatus: {
          ...state.deployMintStatus,
          [id]: {
            ...state.deployMintStatus[id],
            deployed: deployEvent === "deployed",
            address,
          },
        },
      };
    case UPDATE_NFT_STATE:
      const { collectionId, nftId, event: mintEvent } = action.payload;

      if (state.deployMintStatus[collectionId]?.mintComplete) {
        return state;
      }

      if (mintEvent === "minting_complete") {
        return {
          ...state,
          deployMintStatus: {
            ...state.deployMintStatus,
            [collectionId]: {
              ...state.deployMintStatus[collectionId],
              mintComplete: true,
            },
          },
        };
      }

      return {
        ...state,
        deployMintStatus: {
          ...state.deployMintStatus,
          [collectionId]: {
            ...state.deployMintStatus[collectionId],
            nfts: {
              ...state.deployMintStatus[collectionId]?.nfts,
              [nftId]: {
                minted: mintEvent === "mint",
              },
            },
          },
        },
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: {
          name: action.payload.name,
          data: action.payload.data,
        },
      };

    case CLOSE_MODAL:
      return { ...state, modal: {} };

    default:
      return state;
  }
};

export default appReducer;
