import { useEffect, useState } from "react";
import Sort from "../../shared/Sort";
import Search from "../../shared/Search";
import {
  ActionsWrapper,
  Separator,
  CodeCheckBox,
  Container,
  LoadingContainer,
} from "./styles";
import {
  ACTION_BAR_TYPES,
  COLLECTIONS_FILTER_OPTIONS,
  COLLECTIONS_SORT_OPTIONS,
  NFTS_FILTER_OPTIONS,
  NFTS_SORT_OPTIONS,
  CLAIMS_UI_LINK,
} from "../../shared/constants";
import Filter from "../../shared/Filter";
import { ActionButton } from "../../views/CollectionPage/styles";
import LoadingBar from "../LoadingBar";
import { goLiveOrUnlive, getCollection } from "../../api";

interface ActionsBarProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  sortValue: string;
  setSortValue: (value: string) => void;
  filterValue: string[];
  setFilterValue: (value: string[]) => void;
  type: ACTION_BAR_TYPES;
  filterMode?: string;
  claim_code?: string;
  collectionId: number;
  item?: any;
}

const ActionsBar: React.FC<ActionsBarProps> = ({
  searchValue,
  setSearchValue,
  sortValue,
  setSortValue,
  filterValue,
  setFilterValue,
  type,
  filterMode,
  claim_code,
  collectionId,
  item,
}) => {
  const [radioButtonState, setRadioState] = useState(false);
  const [copied, setCopied] = useState(false);
  const [colLiveOrNot, setLiveOrNot] = useState(Boolean);
  const [live, setLive] = useState(Boolean);
  const sortOptions = () => {
    if (type === ACTION_BAR_TYPES.COLLECTION) return COLLECTIONS_SORT_OPTIONS;
    if (type === ACTION_BAR_TYPES.NFTS) return NFTS_SORT_OPTIONS;
    return [];
  };

  async function getLiveState(collId: number) {
    const col = await getCollection(collId);
    setLiveOrNot(!col!.live);
    setLive(!col!.live);
  }

  if (type === ACTION_BAR_TYPES.NFTS) {
    getLiveState(collectionId);
  }

  const filterOptions = () => {
    if (type === ACTION_BAR_TYPES.COLLECTION) return COLLECTIONS_FILTER_OPTIONS;
    if (type === ACTION_BAR_TYPES.NFTS) return NFTS_FILTER_OPTIONS;
    return [];
  };

  function checkBoxChange() {
    setCopied(false);
    setRadioState((prevState) => !prevState);
  }

  function liveButtonChange() {
    setLive((prevState) => !prevState);
    setLiveOrNot((prevState) => !prevState);
  }

  // TODO change to navigator.clipboard.writeText(claimUrl) when we use https
  function copyClaims(withCode: boolean) {
    const el = document.createElement("input");
    if (withCode) {
      el.value = `${CLAIMS_UI_LINK}/${collectionId}#${claim_code}`;
    } else {
      el.value = `${CLAIMS_UI_LINK}/${collectionId}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  async function goLive(collectionId: number) {
    await goLiveOrUnlive(collectionId);
    liveButtonChange();
  }

  return (
    <Container>
      <LoadingContainer>
        <LoadingBar {...item} />
      </LoadingContainer>
      <ActionsWrapper>
        <Search
          value={searchValue}
          onChange={setSearchValue}
          placeholder="Search..."
        />
        <Separator type="vertical" />
        <Sort
          options={sortOptions()}
          value={sortValue}
          onChange={setSortValue}
        />
        <Separator type="vertical" />
        <Filter
          options={filterOptions()}
          mode={filterMode}
          value={filterValue}
          onChange={setFilterValue}
        />
        <Separator type="vertical" />
        {type === ACTION_BAR_TYPES.NFTS
          ? [
              colLiveOrNot
                ? [
                    <ActionButton
                      shape="round"
                      type="primary"
                      onClick={() => {
                        goLive(collectionId);
                      }}
                    >
                      {!live ? "Live!" : "Go live!"}
                    </ActionButton>,
                  ]
                : [
                    <ActionButton
                      shape="round"
                      type="primary"
                      style={{ background: "red" }}
                      onClick={() => {
                        goLive(collectionId);
                      }}
                    >
                      {!live ? "Live!" : "Go live!"}
                    </ActionButton>,
                  ],
              radioButtonState
                ? [
                    <ActionButton
                      shape="round"
                      type="primary"
                      onClick={() => {
                        window.location.href = `${CLAIMS_UI_LINK}/${collectionId}/#${claim_code}`;
                      }}
                    >
                      Claim!
                    </ActionButton>,
                    <ActionButton
                      shape="round"
                      type="primary"
                      onClick={() => {
                        copyClaims(true);
                      }}
                    >
                      {!copied ? "Copy claims link" : "Copied!"}
                    </ActionButton>,
                  ]
                : [
                    <ActionButton
                      shape="round"
                      onClick={() => {
                        window.location.href = `${CLAIMS_UI_LINK}/${collectionId}`;
                      }}
                    >
                      Claim!
                    </ActionButton>,
                    <ActionButton
                      shape="round"
                      onClick={() => {
                        copyClaims(false);
                      }}
                    >
                      {!copied ? "Copy claims link" : "Copied!"}
                    </ActionButton>,
                  ],
              <CodeCheckBox
                type="checkbox"
                id="withCode"
                onChange={() => checkBoxChange()}
              />,
              <label htmlFor="withCode">With claims code</label>,
            ]
          : null}
      </ActionsWrapper>
    </Container>
  );
};

export default ActionsBar;
