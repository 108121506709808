import { NFT_STATUS } from "../views/CollectionPage/NFTBox";

export enum DROP_TYPES {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum ACTION_BAR_TYPES {
  COLLECTION,
  NFTS,
}

export enum COLLECTION_STATES {
  NOT_PUBLISHED = "NOT_PUBLISHED",
  PUBLISHING = "PUBLISHING",
  PUBLISHED = "PUBLISHED",
  DROPPED = "DROPPED",
  ALL_CLAIMED = "ALL_CLAIMED",
}

export const DATE_FORMAT = "MMM Do YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MYSQL_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const ALREADY_PUBLISHED_MESSAGE =
  "Collection has already been published to the chain";
export const COLLECTIONS_SORT_OPTIONS = [
  { label: "Drop date", value: "dropDate" },
  { label: "Name", value: "name" },
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

export const NFTS_SORT_OPTIONS = [
  { label: "Name", value: "name" },
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

export const COLLECTIONS_FILTER_OPTIONS = [
  { label: "published", value: "published" },
  { label: "scheduled", value: "scheduled" },
  { label: "dropped", value: "dropped" },
];

export const NFTS_FILTER_OPTIONS = [
  { label: "NOT MINTED", value: NFT_STATUS.NOT_MINTED },
  { label: "MINTED", value: NFT_STATUS.MINTED },
  { label: "CLAIMABLE", value: NFT_STATUS.CLAIMABLE },
  { label: "CLAIMED", value: NFT_STATUS.CLAIMED },
];

export enum MODAL_NAMES {
  VIEW_NFT = "VIEW_NFT",
}

export const WS_MINT_ENDPOINT = `ws://${window.location.host}`;
export const WSS_MINT_ENDPOINT = `wss://${window.location.host}`;
export const CLAIMS_UI_LINK = `${window.location.origin}/claims_ui`;

export const COLORS = {
  green: "green",
  orange: "orange",
  geekblue: "geekblue",
  red: "red",
};

export const TESTNET_OPENSEA = "https://testnets.opensea.io/assets/mumbai";
