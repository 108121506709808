import styled from "styled-components";
import { Form } from "antd";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
`;

export const FormWrapper = styled(Form)`
  margin: 2rem;

  && {
    .ant-form-item-label > label {
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

export const ImagePreview = styled.img`
  height: 100%;
  width: 100%;
`;