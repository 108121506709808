import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./views/HomePage";
import Collection from "./views/CollectionPage";
import AddOrEditNFT from "./views/CreateOrEditNFTPage";
import CreateOrEditColection from "./views/CreateOrEditCollectionPage";
import Login from "./views/Login";

import "antd/dist/antd.css";
import { useAuth } from "./hooks/useAuth";
import { AuthProps } from "./shared/helpers";
import { GlobalContext } from "./context/GlobalState";
import { WSS_MINT_ENDPOINT, WS_MINT_ENDPOINT } from "./shared/constants";
import socketIOClient from "socket.io-client";

export interface MintedEventProps {
  collectionId: number;
  event: string;
  address?: string;
  nftId?: number;
}

const App: React.FC = () => {
  const auth: AuthProps = useAuth();
  const { updateDeployedCollection, updateMintedNFT } =
    useContext(GlobalContext);

  useEffect(() => {
    var socket = socketIOClient();
    if (window.location.protocol === "http:") {
      socket = socketIOClient(WS_MINT_ENDPOINT);
    } else {
      socket = socketIOClient(WSS_MINT_ENDPOINT);
    }

    socket.on("FromMinted", (data: MintedEventProps) =>
      updateMintedNFT({ ...data })
    );
    socket.on("FromDeployed", (data: MintedEventProps) =>
      updateDeployedCollection({ ...data })
    );
  }, []);

  if (!auth.user && auth.user !== null) {
    return (
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>

        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/collections">
        <Home />
      </Route>
      <Route exact path="/collections/create">
        <CreateOrEditColection />
      </Route>
      <Route exact path="/collections/:id">
        <Collection />
      </Route>
      <Route exact path="/collections/:id/edit">
        <CreateOrEditColection edit />
      </Route>
      <Route exact path="/collections/:id/add">
        <AddOrEditNFT />
      </Route>
      <Route exact path="/collections/:id/edit/:nftId">
        <AddOrEditNFT edit />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>

      <Redirect to="/collections" />
    </Switch>
  );
};

export default App;
